<template>
  <div class="failure">
    <GlobalInfoBar title="核心故障" content="核心故障将用于门店端小程序-首页报价时，提供展示快捷选项" />
    <div class="case">
      <div class="case-title">
        <img class="case-title_icon" src="@/assets/images/icon_hsfl_.png" alt />
        <div class="case-title_max">回收分类</div>
      </div>
      <div class="class_type">
        <el-radio-group v-model="calssSelect" v-if="calssSelect.length != 0">
          <el-radio-button v-for="item in classList" :key="item.id" :label="item.id">{{ item.name }}</el-radio-button>
        </el-radio-group>
        <span v-else class="tip">暂无分类数据，请先添加，立即前往
          <el-link type="primary" href="/classification/add">分类管理</el-link>
          添加</span>
      </div>
      <div class="class_type" v-if="calssSelect.length != 0 && brandType == 'mobile'">
        <el-radio-group v-model="detectionType">
          <el-radio-button v-for="item in detectionTypeList" :key="item.id" :label="item.id">{{
            item.label
          }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <GlobalChunk icon="search" title="故障选项">
        <div class="case-title2">
          <div style="text-align: left; margin-bottom: 20px">
            <el-button size="small" type="primary" icon="el-icon-plus" @click="AddFailure"
              :disabled="calssSelect.length == 0">添加
            </el-button>
          </div>
          <div class="case-title2_min">
            {{
              detectionType == '01' ? '说明: 该信息将显示在门店端小程序-首页-核心故障处(字数限制8字内)' : '说明：该功能仅用于系统检测方式的补充功能选择'
            }}
          </div>
        </div>

        <el-table border :data="faultTypeList.records">
          <el-table-column type="index" width="100" align="center" label="序号">
          </el-table-column>
          <el-table-column align="center" label="故障ID" prop="failureCode">
          </el-table-column>
          <el-table-column align="center" label="故障名称" prop="failureName">
          </el-table-column>
          <el-table-column align="center" label="添加时间" prop="createTime" width="200" />
          <el-table-column label="设置机型" align="center">
            <template slot-scope="{ row }">
              <p v-if="row.machineConfig == '00'">全部机型</p>
              <p v-else class="link primary" @click="modelConfigNext(row)">指定{{ row.machineNum }}个机型</p>
              <p class="link danger" @click="$refs['modelConfigDia'].open(row)">修改配置</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="排序">
            <template slot-scope="scope">
              <el-input-number size="small" v-model="scope.row.sort" :min="0"
                @change="handleChange(scope.row)"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="启用/禁用" prop="createTime" align="center">
            <template slot-scope="{ row }">
              <el-switch inactive-color="#bebebe" @change="handleSwitchUsed(row)" v-model="row.isEnable"></el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="340px">
            <template slot-scope="scope">
              <el-button size="small" @click.native.prevent="deleteBtn(scope.row)" type="danger">删除</el-button>
              <el-button size="small" @click.native.prevent="dblclick(scope.row)" type="primary">编辑</el-button>
              <el-button size="small" @click.native.prevent="configOption(scope.row)" type="warning">故障选项
              </el-button>
              <el-button size="small" @click.native.prevent="configPrompt(scope.row)" type="success">检测提示
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="text-align: right; margin-top: 20px" @current-change="handleCurrentChange"
          :current-page="currentPage4" layout="total,  prev, pager, next, jumper" :total="faultTypeList.total"
          :background="true">
        </el-pagination>
      </GlobalChunk>
    </div>
    <!-- 启用、禁用提示 -->
    <el-dialog title="提示" :visible.sync="usedDialogVisible" :close-on-click-modal="false" width="500px"
      @closed="handleDialogClosed">
      <div style="
                  font-size: 16px;
                  color: #666666;
                  line-height: 50px;
                ">
        <div style="text-align: center;">{{ usedType ? "是否确认启用该故障项？" : "是否确认禁用该故障项？" }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="usedDialogVisible = false">取消</el-button>
        <el-button type="primary" @click.native="usedSuccess">确定</el-button>
      </span>
    </el-dialog>
    <!--弹框-->
    <GlobalDialog :title="dialogTitle" :show="dialogVisible" :width="dialogWidth" :sureLoading="sureLoading"
      @resetPopupData="resetPopupData" @submitPopupData="submitPopupData" @onClose="onClose">
      <div class="AddDialog" v-if="this.removeFaultAdd === 'AddBtn' || this.removeFaultAdd === 'EditBtn'
        ">
        <div class="title mb-20" style="color: #ff687b;">
          {{
            detectionType == '01' ? '说明: 该信息将显示在门店端小程序-首页-核心故障处' : '说明：该功能仅用于系统检测方式的补充功能选择'
          }}
        </div>
        <div class="flex mb-20">
          <div style="width: 135px;text-align: right;">核心故障ID：</div>
          <el-input
            :disabled="this.removeFaultAdd === 'EditBtn' && rowObj.failureCode != null && rowObj.failureCode != ''"
            v-model.trim="failureCode" size="small" maxlength="4" placeholder="请输入核心故障ID">
            <template slot="prepend">{{
              brandType == 'mobile' ? 'MB' : brandType == 'tablet' ? 'PAD' : brandType == 'laptop' ? 'PC' : 'QT'
            }}
            </template>
          </el-input>
        </div>
        <div class="flex mb-20">
          <div style="width: 135px;text-align: right;">核心故障名称：</div>
          <el-input v-model.trim="AddTextarea" size="small" maxlength="8" placeholder="请输入故障类型（8字内）" clearable></el-input>
        </div>
      </div>
      <div style="font-size: 16px; color: #666666" v-if="this.removeFaultAdd === 'deleteBtn'">
        是否确认删除该核心故障？
      </div>
    </GlobalDialog>
    <!-- 故障选项 -->
    <DialogAction v-bind="optionDialogProps"
                  @close="optionDialogProps.show = false"
                  @config="optionConfig"
    >
      <template v-slot:default>
        <div class="option_form">
          <p class="lv_fc_red">说明：若未添加异常项，则用户选择否时，无需勾选故障选项</p>
          <!--          <h3>功能正常选项（必传，仅一个）</h3>-->
          <!--          <div class="option_normal">-->
          <!--            <el-input v-model="optionsData.optionName"-->
          <!--                      placeholder="请输入正常选项描述，最多15个字"-->
          <!--                      clearable-->
          <!--                      maxlength="15"-->
          <!--            />-->
          <!--          </div>-->
<!--          <h3>功能异常选项（至少填一个）</h3>-->
          <div class="option_abnormal"
               v-for="(item,index) in optionsData.data"
               :key="index"
          >
            <span>{{ index + 1 }}.</span>
            <el-input
                style="margin-right: 10px;"
                v-model="item.optionName"
                maxlength="15"
                placeholder="请输入异常选项描述，最多15个字"
            />
            <UploadElement v-bind="{existImage: item.url}"
                           :exist-image="item.exampleImg"
                           @handleChange="v=>optionsData.data[index].exampleImg = v"/>
            <el-button type="danger"
                       size="mini"
                       style="margin-left: 10px;"
                       @click="optionsData.data.splice(index,1)">
              刪除选项
            </el-button>
          </div>
          <el-button @click="addOption" style="margin-top: 10px;" icon="el-icon-plus" type="primary">添加异常选项
          </el-button>
        </div>
      </template>
    </DialogAction>
    <!-- 检测引导 -->
    <DialogAction v-bind="promptDialogProps"
                  @close="promptDialogProps.show = false"
                  @config="promptConfig"
    >
      <template v-slot:default>
        <div class="prompt_form">
          <el-form :model="promptForm" ref="promptForm">
            <el-form-item label="提示内容" label-width="80px"
                          prop="detectionPrompt"
                          :rules="[{required:true,message:'请输入检测提示内容',trigger:'blur'}]">
              <el-input v-model="promptForm.detectionPrompt" placeholder="请输入检测提示内容"
                        maxlength="22"/>
            </el-form-item>
          </el-form>
        </div>
        <div class="prompt_imgae">
          <p>检测引导示例图</p>
          <UploadElement :existImage="promptForm.detectionPromptImg" @handleChange="promptImage"/>
        </div>
      </template>
    </DialogAction>

    <ModelConfigDia ref="modelConfigDia" @confirm="modelConfigNext" />
    <ModelSelectDia ref="modelSelectDia" :ids="machineIdList" :machineTypeId="calssSelect" @confirm="modelSelectConfirm" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import DialogAction from "@/components/common/DialogAction.vue";
import UploadElement from "@/components/global/components/elements/upload-element.vue";
import ModelConfigDia from './dialog/ModelConfigDia';
import ModelSelectDia from './dialog/ModelSelectDia';

export default {
  name: "failure",
  components: { DialogAction, UploadElement, ModelConfigDia, ModelSelectDia },
  data() {
    return {
      promptForm: {
        detectionPrompt: "",
        // 上传图片
        detectionPromptImg: "",
      },
      // 故障编辑数据
      optionsData: {
        // 正常选项
        optionName: undefined,
        // 故障集合
        data: [],
      },
      // 检测弹窗
      promptDialogProps: {
        show: false,
        currentData: {},
        title: "检测引导",
      },
      // 故障选项
      optionDialogProps: {
        show: false,
        currentData: {},
        title: "故障选项",
        width: "40%",
      },
      kerneId: "",
      usedType: false,
      usedDialogVisible: false,
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      dialogWidth: 380,
      faultTypeList: [],
      removeFaultAdd: "",
      AddTextarea: "",
      failureCode: "",
      FaultId: "",
      classList: [],
      brandType: "mobile",
      calssSelect: "19",
      currentPage4: 1,
      detectionType: "01",
      rowObj: null,
      sureLoading: false,
      detectionTypeList: [
        {
          id: '01',
          label: '人工确认'
        },
        {
          id: '02',
          label: '全面检测'
        },
      ],

      modelConfigFormData: {},
      machineIdList: []
    };
  },
  created() {
    this.getClassList();
    // this.FaultBtnList();
  },
  watch: {
    calssSelect(val) {
      console.log(val);
      this.classList.forEach(item => {
        if (item.id == val) {
          this.brandType = item.type
        }
      })
      this.detectionType = '01'
      this.FaultBtnList();
    },
    detectionType() {
      this.FaultBtnList();
    }
  },
  methods: {
    modelConfigNext({ id, machineConfig }) {
      this.modelConfigFormData = { id, machineConfig }
      if (machineConfig == '00') {
        this.modelSelectConfirm()
      } else {
        _api.getCoreFailureMachineConfig({ id }).then(res => {
          this.machineIdList = res.data.machineIdList
          this.$nextTick(() => {
            this.$refs['modelSelectDia'].open()
          })
        })
      }
    },
    modelSelectConfirm(machineIdList) {
      _api.saveCoreFailureMachineConfig({
        ...this.modelConfigFormData,
        machineIdList
      }).then(res => {
        this.$message.success("修改成功");
        this.FaultBtnList()
      })
    },
    // 故障选项
    configOption(row) {
      _api.getCoreFaultOption(row.id).then(r => {
        const data = r.data ?? {};
        // const {optionName} = data.normalOpt ? data.normalOpt : {optionName: ""};
        const errorOptList = data??[{
          optionName: "",
          exampleImg: ""
        }];
        this.optionsData = {
          // optionName,
          data:errorOptList.length?errorOptList:[{
            optionName: "",
            exampleImg: ""
          }]
        }
        this.optionDialogProps = {
          ...this.optionDialogProps,
          show: true,
          currentData: row
        }
      })
    },

    // 添加故障选项
    addOption() {
      this.optionsData.data.push({
        optionName: "",
        exampleImg: ''
      })
    },

    // 故障确定
    optionConfig() {
      const {data, optionName} = this.optionsData;
      const isNull = data.every(v => v.optionName);
      // if (!optionName) {
      //   return this.$message.error("请填写正常选项描述")
      // }
      if (!isNull) {
        return this.$message.error("存在异常选项未填写描述")
      }
      const dataBody = {
        // normalOpt: {
        //   optionName
        // },
        errorOptList: data,
        coreFailureId: this.optionDialogProps.currentData.id
      }
      _api.saveCoreFaultOption(dataBody).then(r => {
        this.optionDialogProps.show = false;
        this.FaultBtnList(this.currentPage4);
        this.$message.success("操作成功");
      })
    },

    // 检测提示
    configPrompt(row) {
      this.promptDialogProps = {
        ...this.promptDialogProps,
        show: true,
        currentData: row
      };
      this.promptForm = {
        detectionPromptImg: row.detectionPromptImg,
        detectionPrompt: row.detectionPrompt
      }
    },
    // 检测引导上传图片
    promptImage(v) {
      this.promptForm.detectionPromptImg = v;
    },
    // 检测确定
    promptConfig() {
      this.$refs['promptForm'].validate(valid => {
        if (!valid) {
          return false;
        }
        _api.saveDetectionPrompt({...this.promptForm, id: this.promptDialogProps.currentData.id}).then(r => {
          this.promptDialogProps.show = false;
          this.$message.success("操作成功");
          this.FaultBtnList(this.currentPage4);
        });
      });
    },

    //启用/禁用
    handleSwitchUsed(row) {
      this.usedDialogVisible = true
      this.usedType = row.isEnable
      this.kerneId = row.id
    },
    //关闭弹窗
    handleDialogClosed() {
      this.handleCurrentChange();
    },
    //禁用、启用确定
    usedSuccess() {
      _api.updateKernel({
        isEnable: this.usedType,//是否上架(false 否，true 是)
        id: this.kerneId,
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.usedDialogVisible = false
        }
      })
    },
    handleChange(row) {
      console.log(row, "计数器");
      _api
        .platformupdateSort({
          id: row.id,
          sort: row.sort,
        })
        .then((res) => {
          console.log(res, "排序成功");
          this.FaultBtnList();
        });
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      const pams = {
        machineTypeId: this.calssSelect,
        detectionType: this.detectionType,
        pageNum: val,
        pageSize: 10,
      };
      _api.coreFailurehxin(pams).then((res) => {
        if (res.code === 1) {
          this.faultTypeList = res.data;
          console.log("成功");
        }
      });
    },

    // 类型按钮数组请求
    FaultBtnList() {
      const pams = {
        machineTypeId: this.calssSelect,
        detectionType: this.detectionType,
        pageNum: 1,
        pageSize: 10,
      };
      _api.coreFailurehxin(pams).then((res) => {
        if (res.code === 1) {
          this.faultTypeList = res.data;
        }
      });
    },
    // 双击编辑按钮
    dblclick(row) {
      console.log(row);
      this.dialogTitle = "编辑故障";
      this.removeFaultAdd = "EditBtn";
      this.FaultId = row.id;
      this.AddTextarea = row.failureName;
      this.failureCode = row.failureCode ? row.failureCode.replace('MB', '').replace('PAD', '').replace('PC', '').replace('QT', '') : '';
      this.dialogWidth = 460;
      this.dialogVisible = true;
      this.rowObj = row
    },
    //添加故障类型按钮
    AddFailure() {
      this.AddTextarea = "";
      this.dialogTitle = "添加故障";
      this.removeFaultAdd = "AddBtn";
      this.dialogWidth = 460;
      this.dialogVisible = true;
    },
    //删除故障类型按钮
    deleteBtn(row) {
      this.dialogTitle = "删除故障";
      this.FaultId = row.id;
      this.dialogWidth = 380;
      this.removeFaultAdd = "deleteBtn";
      this.dialogVisible = true;
    },
    // //弹窗取消
    resetPopupData() {
      this.dialogVisible = false;
    },
    //弹窗确定
    submitPopupData() {
      //删除
      if (this.removeFaultAdd === "deleteBtn") {
        const id = JSON.parse(this.FaultId);
        this.sureLoading = true
        _api.failureDel({ id }).then((res) => {
          if (res.code === 1) {
            this.FaultBtnList();
            this.$message({
              message: "删除成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "删除失败",
              type: "error",
            });
          }
          this.dialogVisible = false;
          this.sureLoading = false
        }).catch(err => {
          this.sureLoading = false
        });
      }
      //添加
      else if (this.removeFaultAdd === "AddBtn") {
        console.log(this.failureCode);
        if (!this.failureCode) {
          this.$message.error('请输入核心故障ID')
          return
        }
        if (!this.AddTextarea) {
          this.$message.error('请输入核心故障名称')
          return
        }
        const dto = {
          detectionType: this.detectionType,
          machineTypeId: this.calssSelect,
          failureName: this.AddTextarea,
          failureCode: this.failureCode,
        };
        this.sureLoading = true
        _api.failureAdd(dto).then((res) => {
          if (res.code === 1) {
            this.FaultBtnList();
            this.$message({
              message: "添加成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "添加失败",
              type: "error",
            });
          }
          this.dialogVisible = false;
          this.sureLoading = false
        }).catch(err => {
          this.sureLoading = false
        });
      }
      //编辑
      else if (this.removeFaultAdd === "EditBtn") {
        if (!this.failureCode && !this.rowObj.failureCode) {
          this.$message.error('请输入核心故障ID')
          return
        }
        if (!this.AddTextarea) {
          this.$message.error('请输入核心故障名称')
          return
        }
        const dto = {
          detectionType: this.detectionType,
          failureName: this.AddTextarea,
          failureCode: this.rowObj.failureCode ? this.rowObj.failureCode.replace('MB', '').replace('PAD', '').replace('PC', '').replace('QT', '') : this.failureCode,
          id: this.FaultId,
          machineTypeId: this.calssSelect,
        };
        this.sureLoading = true
        _api.failureAdd(dto).then((res) => {
          if (res.code === 1) {
            this.FaultBtnList();
            this.$message({
              message: "编辑成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "编辑失败",
              type: "error",
            });
          }
          this.dialogVisible = false;
          this.sureLoading = false
        }).catch(err => {
          this.sureLoading = false
        });
      }
    },
    onClose() {
      this.dialogVisible = false;
      this.failureCode = ""
    },
    // 获取分类集合
    getClassList() {
      _api.selectList().then((res) => {
        this.classList = res.data ? res.data : [];
        this.calssSelect = res.data[0].id;
        console.log(this.calssSelect, "切换类型");
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.failure {
  .case {
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    width: 100%;
    padding: 14px 0;

    .class_type {
      padding: 20px;

      .tip {
        font-size: 14px;
        color: #666666;
      }

      /deep/ .el-radio-group {
        .el-radio-button {
          margin-right: 20px;

          .el-radio-button__inner {
            border-radius: 4px;
            border: 1px solid #0981ff;
          }
        }
      }
    }

    .case-title {
      padding: 0 20px;
      display: flex;

      .case-title_icon {
        width: 20px;
        height: 20px;
        margin-right: 3px;
        margin-top: 10px;
      }

      .case-title_max {
        // width: 150px;
        height: 17px;
        font-size: 18px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: bold;
        color: #333333;
        line-height: 41px;
      }

      .case-title_min {
        margin-left: 5px;
        width: 500px;
        height: 13px;
        font-size: 12px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        color: #ff687b;
        line-height: 45px;
      }
    }

    .case-img {
      margin: 20px;
      height: 280px;
      background: #f5f6fa;
      border-radius: 10px;
      display: flex;
      align-items: center;

      .upload_Add {
        margin-bottom: 50px;
      }

      .upload_Class {
        position: relative;
        width: 200px;
        height: 200px;

        .img:hover .mark {
          position: absolute;
          top: 0;
          left: 0;
          width: 148px;
          height: 148px;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            cursor: pointer;
            width: 20px;
            height: 20px;
          }
        }

        .img {
          position: relative;
          padding: 0;
          width: 148px;
          height: 148px;
          border-radius: 12px;
          margin: 0 auto;

          img {
            width: 148px;
            height: 148px;
          }

          .mark {
            display: none;
          }
        }

        .sort {
          width: 120px;
          height: 30px;
          margin: 20px auto;
        }
      }
    }

    .case-title2 {
      display: flex;

      .case-title2_min {
        margin-left: 5px;
        font-size: 12px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        color: #ff687b;
        line-height: 45px;
      }
    }

    .faultBtn {
      display: flex;
      flex-wrap: wrap;
      background: #f5f6fa;
      width: 100%;

      .faultBut_item {
        user-select: none;
        align-items: center;
        margin: 10px 25px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #0981ff;
        border-radius: 15px;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
        color: #0981ff;
        padding: 0 5px;
      }

      .faultBut_item:hover {
        height: 30px;
        background: #0981ff;
        font-size: 14px;
        border: 1px solid #0981ff;
        margin: 10px 25px;
        color: white;
        border-radius: 15px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
      }
    }

    .AddDialog {
      .title {
        width: 232px;
        height: 12px;
        font-size: 12px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        color: #ff687b;
        margin-bottom: 20px;
      }
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  /deep/ .el-input-group__prepend {
    border: 1px solid #0981ff;
    background: #0981ff;
    color: #ffffff;
  }
}

.prompt_form {
  width: 78%;
}

.prompt_imgae {
  margin-top: 10px;
  padding-left: 5px;

  > p {
    font-size: 14px;
    margin-bottom: 10px;

  }
}

.option_form {
  max-height: 60vh;
  overflow: auto;

  > h3 {
    font-size: 14px;
    font-weight: 700;
  }

  .option_normal {
    margin-top: 6px;
    margin-bottom: 16px;
  }

  /deep/ .el-input {
    width: 60%;
  }

  > .option_abnormal {
    margin-top: 10px;
    display: flex;
    align-items: center;

    > span {
      margin-right: 6px;
    }
    /deep/ .el-upload{
      width: 60px;
      height: 60px;
    }
  }
}

.link{
  text-decoration: underline;
  cursor: pointer;
  &.primary{
    color: #0981ff;
  }
  &.danger{
    color: #ff687b;
  }
}
</style>
